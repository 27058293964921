/* global gtag */

import "lazysizes";
import { Clock } from "./clock.js";
var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");

export function init() {
/* every page */
	setupSlideMenu();
	// subscribe section expanders

	var sub_button = document.querySelectorAll(".button.subscribe, #s-expand", "");
	if (sub_button){
		sub_button.forEach((b) => {
			b.addEventListener("click", showSubscribe);
		});
	}
	// content tables
	responsiveTables();
	/* home page */
	// banner
	var banners = document.querySelectorAll(".banners");
	if (banners){
		swapBanners(banners);
	}
	// 2 scrollers: product items and testimonials
	var scrollwraps = document.querySelectorAll("div.scrollwrap");
	if (scrollwraps){
		homeScrollers(scrollwraps);
	}
	/* contact page */
	// shield map on mobile for easy scrolling
	if (document.getElementById("map-click")) {
		document.getElementById("map-click").addEventListener("click", function(e){
			e.targetElement.style.display = "none";
		});
	}
	setupClock();
}

var delay;
window.addEventListener("resize", function(){
	clearTimeout(delay);
	delay = setTimeout(function(){responsiveTables();}, 100);
});

export function setupClock(){
	const el = document.querySelector(".clock");
	if (el){
		const target_date_raw = el.dataset.countdownDate;
		const target_date = new Date(target_date_raw);
		if (new Date() < target_date){
			new Clock(el, target_date);
		}
		else{
			el.remove();
		}
	}
}
export function setupSlideMenu(){
	var slide_triggers = [document.getElementById("mobile-menu"), document.getElementById("slide-menu-close"), document.getElementById("slidemenuclose")];
	slide_triggers.forEach(function(trigger){
		trigger.addEventListener("click", function(){
			document.body.classList.toggle("slide-menu-active");
		});
	});

	$(".slide-menu").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});
	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
}

function showSubscribe(){
	var s_div = document.getElementById("subscribe-expand");
	s_div.classList.toggle("active");
	if (s_div.classList.contains("active")){ // expand
		s_div.style.maxHeight = setMaxHeight(s_div)+"px";
		s_div.scrollIntoView({ behavior: "smooth", inline: "nearest" });
	} else { // collapse
		s_div.style.maxHeight = "0px";
	}


}
function setMaxHeight(expand_div){
	// get height
	var clone = expand_div.cloneNode(true);
	expand_div.after(clone);
	clone.style.maxHeight = "none";
	var clone_height = clone.clientHeight + 30; // a bit of lee-way
	clone.remove();
	// set height
	return clone_height;
}

function swapBanners(wraps){
	wraps.forEach(function(wrap){ /* wrap = .banners */
		var scrolling = false;
		var banners = wrap.getElementsByClassName("banner");
		var nav_items = null;
        
		if (banners.length == 0){
			wrap.style.display = "none";
		} else if (banners.length > 1){
			var nav_wrap = wrap.getElementsByClassName("banner-navigation")[0];
			nav_wrap.style.display = "block";
			nav_items = nav_wrap.querySelectorAll(".banner-nav-item");
			scrolling = setInterval(swap, 7000);
		}
		// scroll
		function swap(){
			wrap.setAttribute("data-animating", true);
			// banner
			var current_b = wrap.querySelectorAll(".banner.active")[0];
			var next_b = (current_b.nextElementSibling != null)? current_b.nextElementSibling: banners[0];
			current_b.classList.remove("active");
			next_b.classList.add("active");
			// nav
			var current_n = wrap.querySelectorAll(".banner-nav-item.active")[0];
			var next_n = (current_n.nextElementSibling != null)? current_n.nextElementSibling: nav_items[0];
			current_n.classList.remove("active");
			next_n.classList.add("active");

			wrap.setAttribute("data-animating", false);
		}
		// clicks
		if(nav_items){
			nav_items.forEach((n) => {
				n.addEventListener("click", function(e){
					clearInterval(scrolling);
					var item_arr = Array.from(nav_items);
					var index = item_arr.indexOf(e.currentTarget);
					var current_n = wrap.querySelectorAll(".banner-nav-item.active")[0];
					var current_b = wrap.querySelectorAll(".banner.active")[0];
					// clear
					current_n.classList.remove("active");
					current_b.classList.remove("active");
					// set
					e.currentTarget.classList.add("active");
					banners[index].classList.add("active");
					// resume timed scroll
					scrolling = setInterval(swap, 7000);
				});
			});
		}
        
	});
}

const homeScrollers = (wraps) => {
	wraps.forEach((wrap) =>{ /* wrap = .scrollwrap */
		var items = wrap.querySelectorAll(".scroller div");
		/* not output to browser if no items */
		if (items.length > 1){
			const slide = () => {
				scroll.setAttribute("data-animating", true);
				scroll.classList.add("slide");

				//listen
				scroll.addEventListener("webkitTransitionEnd", reset);
				scroll.addEventListener("oTransitionEnd", reset);
				scroll.addEventListener("transitionend", reset);

				var current = scroll.children[0]; /* always first */
				var distance = current.clientWidth;
				var next = scroll.children[1];

				scroll.style.transform = "translateX(-"+distance+"px)";

				// set active
				current.classList.remove("active");
				Array.from(document.querySelectorAll(current.dataset.trigger)).forEach(el=> el.classList.remove("active"));
				next.classList.add("active");
				Array.from(document.querySelectorAll(next.dataset.trigger)).forEach(el=> el.classList.add("active"));
				var id = next.getAttribute("id");
				if (nav.children){
					for (let n of nav.children) {
						if (n.getAttribute("data-id") != id){
							n.classList.remove("active");
						}
						else n.classList.add("active");
					}
				}
			
			};
			const reset = () =>{
				scroll.classList.remove("slide");
				//ignore the next transition
				scroll.removeEventListener("webkitTransitionEnd", reset, false);
				scroll.removeEventListener("oTransitionEnd", reset, false);
				scroll.removeEventListener("transitionend", reset, false);

				scroll.appendChild(scroll.children[0]);
				scroll.style.transform = "translateX(0px)";
				setTimeout(slide, 5000);
			};

			var scroll = wrap.getElementsByClassName("scroller")[0];
			var nav = wrap.querySelector(".scroll-nav");
			nav.style.display = "block";
	

			if (scroll.scrollWidth > scroll.clientWidth){
				if (!scroll.getAttribute("data-animating")){
					setTimeout(slide, 5000);
				}
			} else if (scroll.getAttribute("data-animating")){
				scroll.classList.remove("slide");
				scroll.style.transform = "translateX(0px)";
				scroll.setAttribute("data-animating", false);
			}
		}
	});
};

function responsiveTables(){
	var wrap_div = null;
	var content_tables = document.querySelectorAll("#content .page-body table");
	if (content_tables.length > 0 ){
		content_tables.forEach(function(table){
			if (table.offsetWidth > table.closest(".contentwrap").offsetWidth){
				if (!table.parentNode.classList.contains("table-wrap")){
					wrap_div = document.createElement("div");
					wrap_div.classList.add("table-wrap");
					wrap(table, wrap_div);
				}
			} else {
				wrap_div = table.closest("div.table-wrap");
				if (wrap_div) unwrap(wrap_div);
			}
		});
	}
	function wrap(el, wrapper){
		el.parentNode.insertBefore(wrapper, el);
		wrapper.appendChild(el);
	}
	function unwrap(wrapper){
		var parent = wrapper.parentNode;
		// move all children of wrapper to the parent
		while(wrapper.firstChild){
			parent.insertBefore(wrapper.firstChild, wrapper);
		}
		// remove empty wrapper
		parent.removeChild(wrapper);
	}
}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});
// The active campaign forms will call a _form_callback if it exists after a form is processed.
// We can use that to send an analytics event.
window._form_callback = function(id){
	var form = document.getElementById("_form_" + id + "_");
	let url = form.action;
	gtag("event", "generate_lead", {"type": "form", "url": url});
};

window.addEventListener("DOMContentLoaded", init);

export class Clock {
	constructor(el, countTo) {
		this.el = el;
		this.countTo = countTo.getTime()/1000;
		this._time = null;
		this._timeLeft = null;
		this.createElements();
		this.animationFrame();
	}
	get time() {
		return this._time;
	}
	get timeLeft() { 
		return this._timeLeft;
	}
	get months() {
		return Math.floor(this._timeLeft /(60 * 60 * 24 * 30));// seconds into months
	}
	get days() {
		return Math.floor(this._timeLeft % (60 * 60 * 24 * 30) /(60 * 60 * 24));// seconds into days
	}
	get hours() { 
		return Math.floor((this._timeLeft % (60 * 60 * 24)) / (60 * 60));// seconds into hours
	}
	get minutes() { 
		return Math.floor((this._timeLeft % (60 * 60)) / 60);// seconds into minutes
	}
	get seconds() {
		return (this._timeLeft % 60);
	}
	animationFrame() {
		this.update();
		requestAnimationFrame(() => this.animationFrame());
	}
	update() {
		var now = Math.floor(new Date().getTime()/1000);
		if (now !== this._time) {
			this._time = now;
			this._timeLeft = this.countTo - this._time;
			this.drawClock();
		}
	}
	createElements() {
		this.el.innerHTML = `
      <div class="clock__months clock__section">
        <span class="clock__months__number clock__number">${this.months}</span>
        <span class="clock__months__label clock__label">M</span>
      </div>
      <div class="clock__days clock__section">
        <span class="clock__days__number clock__number">${this.days}</span>
        <span class="clock__days__label clock__label">D</span>
      </div>
      <div class="clock__hours clock__section">
        <span class="clock__hours__number clock__number">${this.hours}</span>
        <span class="clock__hours__label clock__label">H</span>
      </div>
      <div class="clock__minutes clock__section">
        <span class="clock__minutes__number clock__number">${this.minutes}</span>
        <span class="clock__minutes__label clock__label">M</span>
      </div>
      <div class="clock__seconds clock__section">
        <span class="clock__seconds__number clock__number">${this.seconds}</span>
        <span class="clock__seconds__label clock__label">S</span>
      </div>
    `;
		this.monthsEl = this.el.querySelector(".clock__months__number");
		this.daysEl = this.el.querySelector(".clock__days__number");
		this.hoursEl = this.el.querySelector(".clock__hours__number");
		this.minutesEl = this.el.querySelector(".clock__minutes__number");
		this.secondsEl = this.el.querySelector(".clock__seconds__number");

	}
	drawClock() {    
		if (this.months > 0) {
			this.monthsEl.innerHTML = this.months;
		} else {
			this.el.querySelector(".clock__months").style.display = "none";
		}
		this.daysEl.innerHTML = this.days;
		this.hoursEl.innerHTML = this.hours;
		this.minutesEl.innerHTML = this.minutes;
		this.secondsEl.innerHTML = this.seconds;    
	}
}